export const theme = {
  colors: {
    primary: "#4870ac",
    secondary: '#a2b6d4',
    text: '#40464f',
    background: 'white',
    block: '#f6f8fabd',
    highlight: '#ffffb5c2',
    shadow: '#e1e4ea',
    light: '#5bb3ff',
    grey: '#b2b2b2',
    lightgrey: '#eef2f5',
    tertiary: '#6e8dbd',
  },
};

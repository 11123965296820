import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import Account from '../Account/Account';
import { ReactComponent as SvgMemu } from '../../assets/svg/memu.svg';
import { Link } from 'react-router-dom';

const BarContainer = styled.header`
    z-index: 999;
    align-items: center;
    right: 0;
    left: 0;

    a {
        font-weight: 700;
        color: ${theme.colors.secondary};
        &:hover {
            color: ${theme.colors.secondary};
            text-decoration: underline 1px;
            text-underline-offset: .4rem;
        }
    }

    @media (max-width: 850px) {
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        box-shadow: 0px 3px 6px #ffffff81;
        /* background-color: #e1e7ee8c; */
        background-image: linear-gradient(0, #e1e7ee8c,#eef2f5);

        display: flex;  
        justify-content: space-between; 
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        transition: 0.2s linear;

        &.active{
            padding-bottom: 11.5rem;
            transition: 0.2s linear;
        }

        a {
            /* color: ${theme.colors.tertiary}; */
            &:hover {
                color: ${theme.colors.tertiary};
            }
        }

        @keyframes fade-in{  
            0%{ opacity: 0;}
            15%{ opacity: 0;}
            100%{opacity:1;}
        }

        nav.active {
            ul {
                animation: fade-in 1s ease 0s 1;  
                transition: 0.2s linear;
                text-align: center;
                display: flex;
                flex-direction: column; 
                position: absolute;
                top: 2rem;
                left: 0;
                width: 100%;
                min-height: 5rem;
                color: ${theme.colors.text};
                padding: .1rem 0;
            }
        }
    }
`;

const BarList = styled.ul`
    font-size: 1.2rem;
    list-style: none;
    padding: 0 .2rem;
    li {
        padding: 1rem 0;
    }

    @media (max-width: 850px){
        font-family: 'SourceHanSerifCN';
        display: none;
        font-size: .94rem;

        li {
            padding: .6rem;
        }
        a {
            color: ${theme.colors.primary};
        }
        span{
            color: ${theme.colors.primary};
            font-size: .94rem;
            padding: 0;
        }
    }
`;

const Header = styled.h1`
    font-family: 'SourceHanSerifCN';
    font-size: 1.7rem;
    padding: 2rem 0;

    a {
        &:hover {
            /* color: ${theme.colors.primary}; */
        }
    }

    @media (max-width: 850px) {
        padding: .5rem 2rem .9rem 2rem;
        margin: 0;
        font-size: 1.2rem;
    }
`;

const HeaderSpan = styled.span`
    padding: 0;
    /* background-image: linear-gradient(50deg, ${theme.colors.secondary}, ${theme.colors.primary});
    transition: ${theme.colors.secondary} 0.2s ease-out 0s, ${theme.colors.primary} 0.2s ease-in-out 0s;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
        background-image: linear-gradient(45deg, ${theme.colors.primary}, ${theme.colors.primary});
    } */
    color: ${theme.colors.primary};

    @media (max-width: 850px) {
        background-image: none;
        background-clip: unset;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        /* color: ${theme.colors.tertiary}; */
        &:hover {
            background-image: none;
        }
    }
`;

const Menu = styled.div`
    display: none; 
    cursor: pointer;

    @media (max-width: 850px) {
        display: block; 
        position: absolute;
        right: 1.5rem;
        svg {
            height: 1rem;
            width: 1rem;
            padding: .2rem .5rem .25rem .5rem;
            fill: ${theme.colors.tertiary};
        }
    }
`;

const Show = styled.div`
    width: 100vw;
`;

const Bar: React.FC = () => {
    const [isNavActive, setNavActive] = useState(false);
    // const [showCopySuccess, setShowCopySuccess] = useState(false);
    const [owner, setOwner] = useState(false);
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    let lastScrollTop = 0;
    const handleScroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        if (scrollTop > 0 && scrollTop > lastScrollTop) {
            setIsShow(true)
            setNavActive(false)
        } else {
            setIsShow(false)
        }
        lastScrollTop = document.documentElement.scrollTop
    }

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role) {
            if (role === "Owner") {
                setOwner(true);
            }
        }
    }, []);
    const handleCopyRssLink = async () => {
        try {
            const rssLink = 'https://43.139.233.250/api/rss.xml';
            await navigator.clipboard.writeText(rssLink);
            window.alert("RSS link copied to your clipboard !")
        } catch (err) {
            console.error('err:', err);
        }
    };

    return (
        // <Show>
        <Show className={`show ${isShow && 'hide'}`}>
            <BarContainer className={isNavActive ? 'active' : ''}>
                <div>
                    <Header><Link to="/"><HeaderSpan>just-plain.fun</HeaderSpan></Link></Header>
                </div>
                <Menu onClick={() => setNavActive(!isNavActive)}>
                    <SvgMemu />
                </Menu>
                <nav className={isNavActive ? 'active' : ''}>
                    <BarList>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/friends">Friends</Link></li>
                        {!owner && <li>
                            <Link to="#RSS" onClick={handleCopyRssLink}>
                                RSS
                            </Link>
                        </li>}
                        {owner && <li><Link to="/compose">Compose</Link></li>}
                        <Account />
                    </BarList>
                </nav>
            </BarContainer>
        </Show>
    );
};

export default Bar;

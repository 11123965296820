import { theme } from '../../theme';
import styled from 'styled-components';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const PostMarkdownContent = styled.div`
    .syntax-highlighter code {
    font-family: 'JetBrains Mono';
    }

    font-size: .95rem;
    color: ${theme.colors.text};
    margin-bottom: 1.5rem;
    line-height: 1.6;
    word-spacing: 0;
    letter-spacing: 0;
    word-break: break-word;
    word-wrap: break-word;

    p {
        text-align: justify;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        line-height: 2;
        color: ${theme.colors.text};
    }

    a {
        color: ${theme.colors.primary};
        text-decoration: underline 1px;
        text-underline-offset: .3rem;
        word-wrap: break-word;
        padding: 1rem .2rem;
    }

    strong {
        color: ${theme.colors.primary};
        font-family: "SourceHanSerifCN";
    }

    mark {
        background: ${theme.colors.highlight};
        padding: 1px .15rem;
        color: inherit;
    }

    h1,h2,h3,h4,h5,h6 {
        color: ${theme.colors.primary};
        font-family: "SourceHanSerifCN";
        padding: 0;
    }

    h1 {
        font-size: 1.5rem;
        margin: .2rem 0;
        color: ${theme.colors.primary};
    }
    
    h2 {
        font-size: 1.5rem;
        margin: .2rem 0;
        padding: .5rem 0;
        color: ${theme.colors.primary};
    }

    h3 {
        line-height: 1.2;
        font-size: 1.2rem;
        margin: 1.3rem 0;
        border-left: 2.5px solid ${theme.colors.tertiary};
        padding: 0 0.6rem;
    }

    h4,h5,h6 {
        font-size: 1.1rem;
        margin: .8rem 0;
        font-weight: normal;
    }

    li {
        margin: 0;
    }

    ul, ol {
        margin-top: .2rem;
        margin-bottom: .2rem;
        line-height: 2;
        padding-left: 1.25rem;
    }

    ::marker {
        font-weight: 700;
        color: ${theme.colors.secondary};
    }


    ul {
        list-style-type: disc;
    }

    em {
        padding: 0 3px 0 0;
    }

    ul ul {
        list-style-type: square;
    }

    ol {
        list-style-type: decimal;
    }

    li section {
        margin-top: .3rem;
        margin-bottom: .3rem;
        line-height: 1.7rem;
        text-align: justify;
        color: ${theme.colors.text};
        font-weight: 500;
    }

    blockquote {
        display: block;
        overflow: auto;
        color: #304c76;
        border-left: 2.5px solid ${theme.colors.primary};
        padding: 0.9rem 1.875rem 1.5rem 1.25rem;
        margin: 1.25rem 0;
        background: ${theme.colors.block};

        li{
            padding: .3rem 0;
        }
    }

    code {
        font-family: 'JetBrains Mono';
        color: ${theme.colors.primary};
        font-size: 94%;
        font-weight: normal;
        word-wrap: break-word;
        padding: 2px 4px 2px;
        border-radius: 3px;
        margin: 2px;
        background-color: ${theme.colors.block};
        word-break: break-all;
    }

    pre code {
        font-size: .8rem;
        font-family: 'JetBrains Mono';
    }

    pre {
    padding: 0;
    }

    img {
        max-width: 100%;
        margin: 1rem auto;
        display: block;
    }

    hr {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        border: 0;
        border-top: 2px solid #eef2f5;
        border-radius: 2px;
    }

    table {
        display: table;
        text-align: justify;
        overflow-x: auto;
        border-collapse: collapse;
        border-spacing: 0px;
        font-size: .9rem;
        margin: .8rem .8rem 1.4rem .8rem;
    }

    table tr {
        border: 0;
        border-top: 1px solid #ccc;
    }

    table tr th,
    table tr td {
        border: 1px solid #d9dfe4;
        padding: .4rem 1rem;
        text-align: justify;
    }

    table tr th {
        font-family: "SourceHanSerifCN";
        text-align: center;
        font-weight: 700;
        color: ${theme.colors.primary};
    }

    table td {
        min-width: 32px;
    }

    @media (max-width: 850px) {
    h2 {
        font-size: 1.2rem;
        margin: 1rem 0;
    }
    h1{
        font-size: 1.35rem;
    }
    }
`;

export const WithSyntaxHighlighter = ({ content }: { content: any }) => {
    return (
        <ReactMarkdown
            components={{
                code({ node, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '')
                    return match ? (
                        <SyntaxHighlighter
                            // @ts-ignore
                            style={oneLight}
                            language={match[1]}
                            customStyle={{
                                borderRadius: ".4rem",
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                border: "1px solid #f6f8fa",
                                backgroundColor: "#f6f8fabd",
                            }}
                            PreTag="div" {...props}
                            codeTagProps={{ style: { fontFamily: 'inherit' } }}
                        >
                            {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    )
                }
            }}
            remarkPlugins={[remarkGfm]}
        >
            {content}
        </ReactMarkdown>
    );
};

